import HacHeader from './HacHeader/Index.vue'
import HacLeftMenu from './HacLeftMenu/Index.vue'
import BreadCrumb from './BreadCrumb/Index.vue'
import Tabs from './Tabs/Index.vue'
import SelectMultiple from './SelectMultiple/Index.vue'
import TreeMultiple from './TreeMultiple/Index.vue'
export default {
  HacHeader,
  HacLeftMenu,
  BreadCrumb,
  Tabs,
  SelectMultiple,
  TreeMultiple
}
