<template>
  <el-container class="index-container">
    <el-header class="conten-header">
      <hac-header></hac-header>
    </el-header>
    <el-container class="body-container">
      <hac-left-menu></hac-left-menu>
      <el-container class="conten-container">
        <el-main>
          <tabs></tabs>
          <div style="padding: 15px 10px 10px 10px;flex: 1;height: 100%;
    background: #fff;overflow: auto">
            <keep-alive   :include="cachedViews" :exclude="deCachedViews">
              <router-view :key="key"></router-view>
            </keep-alive>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>
<script>
import common from '@/components/common/'
export default {
  name: 'home',
  components: common,
  data () {
    return {
      // loading: this.$store.state.loading.load
    }
  },
  mounted () {

  },
  computed: {
    loading () {
      return this.$store.state.loading.load
    },
    cachedViews () {
      return this.$store.state.tagPage.cachedViews
    },
    deCachedViews () {
      return this.$store.state.tagPage.decachedViews
    },
    key () {
      return this.$route.path
    }
  }

}
</script>
<style scoped lang='less'>
.el-main{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.index-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  flex: 1;
  position: relative;
  background: #f3f4f5;
  .conten-header{
    height: 65px;
  }
  .body-container{
    display: flex;
    overflow: auto;
    .conten-container{
      display: flex;
      flex-direction:column;
      .el-main{
        background: transparent;
        padding: 10px;
      }
    }
  }
  .el-aside, .el-main{
    overflow: auto;
  }
  .el-aside{
    position: absolute;
    height: calc(100% - 65px);
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .conten-header.el-header{
    height: 60px !important;
    padding: 0;
  }
}

.el-aside::-webkit-scrollbar {
  display: none;
}

.el-aside::-webkit-scrollbar {
  display: none;
}

</style>
