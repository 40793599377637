<template>
  <div style="position:absolute;width:100%;height:100%;display:flex;align-items:center;justify-content:center">
    <img style="width:300px;margin-top:-80px" src="../../../../static/images/404/notfind.png">
  </div>
</template>

<script>
export default {
  data () {
    return {}
  },
  methods: {},
  mounted () {
    setTimeout(() => {
      this.$router.push({
        path: '/login'
      })
    }, 3000)
  }
}
</script>
