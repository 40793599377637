/**
 * Created by Administrator on 2018/9/18 0018.
 */
import RouteIndex from './index/'
import RouteHome from './home/'

export default {
  RouteIndex,
  RouteHome
}
