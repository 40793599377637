export default {
  name: 'select-multiple',
  data () {
    return {
      inputValue: '',
      dataList: []
    }
  },
  props: [
    'data',
    'width',
    'value'
  ],
  computed: {
    selectValue: {
      get () {
        if (this.value && this.value.split(',').length > 0) {
          return this.value.split(',')
        } else {
          return []
        }
      },
      set (v) {
        this.$emit('onSetValue', v)
      }
    }
  },
  methods: {
    getList () {
      this.dataList = this.data.map(item => {
        return item.name
      })
    },
    handleCheckAllChange () {
      this.selectValue = this.dataList
    },
    handleCheckClearChange () {
      this.selectValue = []
    },
    selectGroup () {
      const arr = []
      this.data.filter(item => {
        if ((item.name + '').indexOf(this.inputValue) > -1) {
          arr.push(item.name)
        }
      })
      this.dataList = arr
    },
    onDelete (item) {
      const list = this.selectValue
      for (const key in list) {
        if (list[key] === item) {
          list.splice(key, 1)
          this.$emit('onSetValue', list)
          break
        }
      }
    }
  }
}
