import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import VueI18n from 'vue-i18n'
import 'element-ui/lib/theme-chalk/index.css'
import { getCookie } from './util/tools/cookie'
import ElTreeSelect from 'el-tree-select'
Vue.prototype.$echarts = require('echarts')
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    if (store.state.login.token) {
      // 如果没有开通直播课堂，则默认进入到中间页面
      if (to.path.indexOf('/course/') > -1) {
        if (to.path === '/course/authority') {
          next()
        } else {
          if (store.state.login.otoStatus === 'true' || store.state.login.otoStatus === true) {
            next()
          } else {
            router.replace('/course/authority')
          }
        }
      } else {
        next()
      }
    } else {
      router.replace('/login')
    }
  }
})
Vue.use(VueI18n)
Vue.use(ElementUI)
Vue.use(ElTreeSelect)

const i18n = new VueI18n({
  // 语言标识
  locale: getCookie('BELL_PLAY_LANG', 'zh'),
  // 通过切换locale的值来实现语言切换
  // this.$i18n.locale
  messages: {
    // 中文语言包
    zh: require('./util/i18n/zh.js'),
    // 英文语言包
    en: require('./util/i18n/en.js')
  }
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
