import Vue from 'vue'
import Vuex from 'vuex'
import login from './login'
import tabs from './tabs'
import global from './global'
import tagPage from './tagPage'
import header from './header'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    login: login,
    tabs: tabs,
    global: global,
    tagPage: tagPage,
    header: header
  }
})
