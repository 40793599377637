import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiEvaluation = request.Evaluation
export default {
  name: 'analysis',
  data () {
    return {
      qiniu: setting.gbs.qiniu,
      paginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      search_data: {
        filters: [{
          field: 'zoneName',
          operator: 'like',
          value: ''
        }]
      },
      tableList: [],
      categoryTable: [],
      teacherScoreDailog: false,
      teacherScoreTable: [],
      selectSchools: '',
      zongfen: 0,
      averages: 0
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getSchoolDetails (row) {
      console.log(row)
      this.selectSchools = row.zoneName
      this.averages = row.averages
      ApiEvaluation.getSchoolPerformancies.call(this, { paperId: parseInt(this.$route.params.paperId), zoneId: row.zoneId, rank: row.rank }, (res) => {
        this.zongfen = 0
        if (res.teacherPerformances) {
          res.teacherPerformances.map(item => {
            this.zongfen += item.score
          })
        }
        this.teacherScoreTable = res
        this.$nextTick(() => {
          this.teacherScoreDailog = true
        })
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value !== '') {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: {
          paperId: parseInt(this.$route.params.paperId)
        },
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'createTime'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        for (var s in query) {
          if (this.search_data.filters[s] !== undefined) {
            this.search_data[s].filters = query[s]
            data[s] = query[s]
          }
        }
      }
      ApiEvaluation.getSchoolExamStatics.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    },
    getTopCategory () {
      ApiEvaluation.getTopCategory.call(this, {}, (listData) => {
        this.categoryTable = listData
      })
    }
  },
  mounted () {
    this.getList()
  }
}
