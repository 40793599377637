import request from '@/config/request.js'
import setting from '@/config/settings'
const ApiEvaluation = request.Evaluation
// const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
// const regInt = /^\d+?$/ // 非负浮点数
let id = 0
export default {
  name: 'create-teacher-papers',
  data () {
    // var checkTotalClass = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('请输入总课次'))
    //   }
    //   setTimeout(() => {
    //     if (!regInt.test(value)) {
    //       callback(new Error('请输入数字值'))
    //     } else {
    //       callback()
    //     }
    //   }, 1000)
    // }
    // var checkTotalTime = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('请输入总课时'))
    //   }
    //   setTimeout(() => {
    //     if (!regInt.test(value)) {
    //       callback(new Error('请输入数字值'))
    //     } else {
    //       callback()
    //     }
    //   }, 1000)
    // }
    // var checktuition = (rule, value, callback) => {
    //   if (!value) {
    //     return callback(new Error('请输入学费'))
    //   }
    //   setTimeout(() => {
    //     if (!regPos.test(value)) {
    //       callback(new Error('请输入正确的值'))
    //     } else {
    //       callback()
    //     }
    //   }, 1000)
    // }
    // var checkScheduleType = (rule, value, callback) => {
    //   if (value === '' || value === null) {
    //     callback(new Error('请选择排课方式'))
    //   } else {
    //     if (this.calssDetail.dayList.length < 1 && value === 'CLASS_TIME') {
    //       this.$refs.classManageForm.validateField('dayList')
    //     }
    //     callback()
    //   }
    // }
    // var checkDayList = (rule, value, callback) => {
    //   if (value.length < 1 && this.calssDetail.scheduleType === 'CLASS_TIME') {
    //     callback(new Error('请选择排课时间'))
    //   } else {
    //     callback()
    //   }
    // }
    return {
      qiniu: setting.gbs.qiniu,
      paginations: {
        current_page: 1,
        total: 0,
        page_size: 10,
        layout: 'total, sizes, prev, pager, next, jumper'
      },
      search_data: {
        filters: [{
          field: 'paperName',
          operator: 'like',
          value: ''
        }, {
          field: 'status',
          operator: 'eq',
          value: ''
        }]
      },
      tableList: [],
      status: [
        {
          name: '已使用',
          value: true
        },
        {
          name: '未使用',
          value: false
        }
      ],
      myHeaders: { Authorization: this.$store.state.login.token },
      formLabelWidth: '120px',
      papersDailog: false,
      papersDetails: {
        paperName: '',
        categoryPath: [],
        paperUrl: '',
        answerUrl: ''
      },
      categoryTable: [],
      categoryChilderTable: [],
      typeSub: {
        LB: '类别',
        XK: '学科',
        BB: '版本',
        NJ: '年级',
        XM: '项目',
        MK: '模块',
        TYJN_MK: '模块'
      },
      showChilder: [],
      selectItem: [],
      num: 0,
      rules: {
        paperName: [
          { required: true, message: '请输入资料名称', trigger: 'blur' }
        ],
        categoryPath: [
          { required: true, message: '请选择上传类别', trigger: 'change' }
        ]
      },
      props: {
        lazy: true,
        lazyLoad (node, resolve) {
          const { level } = node
          setTimeout(() => {
            const nodes = Array.from({ length: level + 1 })
              .map(item => ({
                value: ++id,
                label: `选项${id}`,
                leaf: level >= 2
              }))
            // 通过调用resolve将子节点数据返回，通知组件数据加载完成
            resolve(nodes)
          }, 1000)
        }
      }
    }
  },
  computed: {
    tableHight: function () {
      return window.innerHeight - 255
    },
    loading: function () {
      return this.$store.state.global.ajax_loading
    }
  },
  methods: {
    getUrlName (url) {
      console.log(url)
      if (url) {
        const name = url.substring(url.lastIndexOf('/') + 1)
        return [{
          name: name,
          url: url
        }]
      }

      return []
    },
    onCancel (formName) {
      this.$refs[formName].resetFields()
      this.papersDetails = {
        paperName: '',
        categoryPath: [],
        paperUrl: '',
        answerUrl: ''
      }
    },
    setEditShow (row) {
      ApiEvaluation.getExamPaper.call(this, {
        paperId: row.paperId
      }, (res) => {
        this.showChilder = []
        this.papersDetails = {
          paperName: res.paperName,
          categoryPath: (res.categoryPath.split(',')).map(i => {
            return parseInt(i)
          }),
          paperUrl: res.paperUrl,
          answerUrl: res.answerUrl,
          paperId: res.paperId
        }
        this.$nextTick(() => {
          ApiEvaluation.getTopCategory.call(this, {}, (listData) => {
            this.categoryTable = listData
            if (this.papersDetails.categoryPath.length > 0) {
              ApiEvaluation.getCategoryMap.call(this, { categoryTopId: this.papersDetails.categoryPath[0] }, (listData) => {
                this.categoryChilderTable = listData
                for (const i in listData) {
                  this.showChilder.push({
                    label: this.typeSub[i],
                    data: listData[i]
                  })
                }
                for (let i = 0; i < this.papersDetails.categoryPath.length; i++) {
                  console.log(this.papersDetails.categoryPath[i])
                  this.startGetCategoryChilderItem(this.papersDetails.categoryPath[i])
                  this.$nextTick(() => {
                    if (i === (this.papersDetails.categoryPath.length - 1)) {
                      console.log(this.showChilder, 'this.showChilder')
                      this.papersDailog = true
                    }
                  })
                }
              })
            }
          })
        })
      })
    },
    answerAvatarSuccess (res, file) {
      this.papersDetails.answerUrl = res.result
    },
    handleAvatarSuccess (res, file) {
      this.papersDetails.paperUrl = res
    },
    beforeAvatarUpload (file) {
      const isFile = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword'].indexOf(file.type)
      if (isFile < 0) {
        this.$message.error('建议上传pdf或者word!')
      }
      return true
    },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = Object.assign(this.papersDetails, [])
          if (this.papersDetails.paperId) {
            ApiEvaluation.updateExamPaper.call(this, {
              paperId: data.paperId,
              paperName: data.paperName,
              categoryPath: data.categoryPath.join(),
              paperUrl: data.paperUrl,
              answerUrl: data.answerUrl
            }, () => {
              this.papersDailog = false
              this.$refs[formName].resetFields()
              this.getList()
            })
          } else {
            ApiEvaluation.addExamPaper.call(this, {
              paperName: data.paperName,
              categoryPath: data.categoryPath.join(),
              paperUrl: data.paperUrl,
              answerUrl: data.answerUrl
            }, () => {
              this.papersDailog = false
              this.$refs[formName].resetFields()
              this.getList()
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getRecursion (index, item) {
      for (const i in item) {
        if (item[i].length > 0) {
          this.getRecursion(index, item[i])
        } else {
          if (item[i].categoryId === index) {
            if (item[i].typeCategoryMap) {
              this.selectItem = item[i].typeCategoryMap
              return
            }
          } else {
            if (item[i].typeCategoryMap) {
              this.getRecursion(index, item[i].typeCategoryMap)
            }
          }
        }
      }
    },
    getIndex (id, data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].label === id) {
          console.log(data[i].label, id)
          this.num = this.showChilder.indexOf(data[i])
          return
        }
      }
    },
    startGetCategoryChilderItem (e) {
      this.selectItem = []
      this.getRecursion(e, this.categoryChilderTable, 0)
      for (const i in this.selectItem) {
        if (this.selectItem[i] && this.selectItem[i].length > 0) {
          this.showChilder.push({
            label: this.typeSub[i],
            data: this.selectItem[i]
          })
        }
      }
    },
    getCategoryChilderItem (e) {
      this.selectItem = []
      this.getRecursion(e, this.categoryChilderTable, 0)
      for (const i in this.selectItem) {
        if (this.selectItem[i] && this.selectItem[i].length > 0) {
          let keyId = 0
          for (let j = 0; j < this.showChilder.length; j++) {
            console.log(this.showChilder[j].label, this.typeSub[i])
            if (this.showChilder[j].label === this.typeSub[i]) {
              keyId = this.showChilder.indexOf(this.showChilder[j])
            }
          }
          if (keyId > 0) {
            console.log(keyId + 1, this.papersDetails.categoryPath)
            this.showChilder.splice(keyId, this.showChilder.length)
            this.papersDetails.categoryPath.splice(keyId + 1, this.papersDetails.categoryPath.length)
          }
          this.showChilder.push({
            label: this.typeSub[i],
            data: this.selectItem[i]
          })
        }
      }
    },
    startGetCategoryChilder (e) {
      ApiEvaluation.getCategoryMap.call(this, { categoryTopId: e }, (listData) => {
        this.showChilder = []
        this.categoryChilderTable = listData
        for (const i in listData) {
          this.showChilder.push({
            label: this.typeSub[i],
            data: listData[i]
          })
        }
      })
    },
    getCategoryChilder (e) {
      ApiEvaluation.getCategoryMap.call(this, { categoryTopId: e }, (listData) => {
        this.showChilder = []
        this.categoryChilderTable = listData
        for (const i in listData) {
          this.showChilder.push({
            label: this.typeSub[i],
            data: listData[i]
          })
        }
        this.papersDetails.categoryPath.splice(1, this.papersDetails.categoryPath.length)
      })
    },
    onShowPapersDailog () {
      this.showChilder = []
      ApiEvaluation.getTopCategory.call(this, {}, (listData) => {
        this.categoryTable = listData
        this.$nextTick(() => {
          if (this.papersDetails.categoryPath.length > 0) {
            this.startGetCategoryChilder(this.papersDetails.categoryPath[0])
            this.$nextTick(() => {
              for (let i = 1; i < this.papersDetails.categoryPath.length; i++) {
                this.startGetCategoryChilderItem(this.papersDetails.categoryPath[i])
              }
            })
          }
          console.log(this.showChilder)
          this.papersDailog = true
        })
      })
    },
    setStatus (row) {
      this.$confirm('确定' + (row.status ? '停用' : '启用') + '该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.updateExamPaperStatus.call(this, { paperId: row.paperId, status: (row.status ? 0 : 1) }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    delPaper (id) {
      this.$confirm('确定删除该记录？, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.recyclePaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    setNew (id) {
      this.$confirm('确定把该条记录设置为最新, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        ApiEvaluation.stickPaper.call(this, { paperId: id }, res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.getList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    /**
     * 搜索事件
     */
    onSearch () {
      const newQuery = {} // 深拷贝
      this.$router.replace({ query: newQuery })
      var where = []
      for (var s in this.search_data.filters) {
        if (this.search_data.filters[s].value !== '') {
          where.push(this.search_data.filters[s])
        }
      }
      this.getList({
        where
      })
    },
    /**
     * 改变页码和当前页时需要拼装的路径方法
     * @param {string} field 参数字段名
     * @param {string} value 参数字段值
     */
    setPath (field, value) {
      var path = this.$route.path
      var query = Object.assign({}, this.$route.query)

      if (typeof field === 'object') {
        query = field
      } else {
        query[field] = value
      }
      this.$router.push({
        path,
        query
      })
    },
    /**
     * 改变当前页事件
     * @param  {number} page 当前页码
     */
    onChangeCurrentPage (page) {
      this.getList({
        page,
        fn: () => {
          this.setPath('page', page)
        }
      })
    },
    /**
     * 改变每页显示数量事件
     * @param  {number} size 当前每页显示数量
     */
    onChangePageSize (pageSize) {
      this.getList({
        pageSize,
        fn: () => {
          this.setPath('page_size', pageSize)
        }
      })
    },
    getList ({
      page,
      pageSize,
      where,
      fn
    } = {}) {
      var query = this.$route.query
      this.paginations.current_page = page || parseInt(query.page) || 1
      this.paginations.page_size = pageSize || parseInt(query.page_size) || this.paginations.page_size
      var data = {
        offset: (this.paginations.current_page - 1) * this.paginations.page_size,
        limit: this.paginations.page_size,
        data: {},
        filters: null,
        orFilters: null,
        order: 'desc',
        sort: 'stick'
      }
      if (where) {
        data = Object.assign(data, { filters: where } || {})
      } else {
        for (var s in query) {
          if (this.search_data.filters[s] !== undefined) {
            this.search_data[s].filters = query[s]
            data[s] = query[s]
          }
        }
      }
      ApiEvaluation.getPaperInfo.call(this, data, (listData) => {
        this.tableList = listData.content
        this.paginations.total = parseInt(listData.totalElements)
        fn && fn()
      })
    }
  },
  mounted () {
    this.getList()
    console.log(this.getUrlName('https://qiniu.anqinyun.com/paperAnswer/20210902173233080155741.pdf'))
  }
}
