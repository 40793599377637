import request from '@/config/request.js'
import setting from '@/config/settings'
import { Message } from 'element-ui'
const ApiLogin = request.UserLogin
export default {
  name: 'login',
  data () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.resetlogin.confirmPassword !== '') {
          this.$refs.resetPass.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请重复输入新密码'))
      } else if (value !== this.resetlogin.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      qiniu: setting.gbs.qiniu,
      login: {
        mobile: this.$store.state.login.logininfo.loginName,
        password: this.$store.state.login.logininfo.loginPwd,
        validCodeKey: '',
        validCodeValue: '',
        rememberMe: false
      },
      resetlogin: {
        mobile: '',
        newPassword: '',
        confirmPassword: '',
        code: ''
      },
      loginRules: {
        mobile: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        validCodeValue: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      checked: true,
      loading: false,
      codeChange: '',
      changeTeacher: false,
      changeTeacherList: [],
      appUrl: setting.gbs.host,
      changeItem: '',
      teacherID: '',
      codeUrl: '',
      loginType: 'pass',
      number: localStorage.getItem('bell-min') ? localStorage.getItem('bell-min') : -1,
      resetnumber: localStorage.getItem('bell-reset-min') ? localStorage.getItem('bell-reset-min') : -1,
      resetPassDialog: false,
      passRules: {
        mobile: [
          { required: true, message: '请输入新账号', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 重置密码
    onResetPassWord (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          ApiLogin.resetPassword.call(this, { mobile: this.resetlogin.mobile, password: this.resetlogin.newPassword, authCode: this.resetlogin.code }, item => {
            this.resetnumber = -1
            this.resetPassDialog = false
            Message({
              message: item.result,
              type: 'success'
            })
          })
        }
      })
    },
    onChangeLoginType (type) {
      this.loginType = type
      this.$refs.loginruleForm.validate((valid) => {
      })
    },
    loginIn (loginruleForm) {
      const that = this
      this.$refs[loginruleForm].validate((valid) => {
        if (valid) {
          ApiLogin.login.call(this, { userName: that.login.mobile, password: that.login.password, validCodeValue: that.login.validCodeValue, validCodeKey: that.login.validCodeKey, rememberMe: false, ajaxType: 'form' }, res => {
            if (res && res.token) {
              this.$store.dispatch('loginIn', 'Bearer ' + res.token)
              console.log(res, '123123')
              this.$router.push({
                path: '/evaluation'
              })
            } else {
              Message({
                message: res,
                type: 'error'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    validCodeChange () {
      const randomKey = new Date().getTime() + Math.random()
      this.login.validCodeKey = randomKey
      this.$nextTick(() => {
        this.login.validCodeKey = randomKey
        this.codeUrl = this.appUrl + 'app/rest/school/green/security/validCodeKey?key=' + randomKey
      })
    },
    loginValidateCodeByManager (fn) {
      ApiLogin.loginValidateCodeByManager.call(this, { mobile: this.login.mobile }, item => {
        Message({
          message: item.result,
          type: 'success'
        })
        fn()
      })
    },
    changeMine () {
      this.number = this.number - 1
      localStorage.setItem('bell-min', this.number)
    },
    resetChangeMine () {
      this.resetnumber = this.resetnumber - 1
      localStorage.setItem('bell-reset-min', this.resetnumber)
    },
    sendResetCodeChange () {
      const that = this
      if (that.resetnumber > -1) {
        return
      }
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (reg.test(that.resetlogin.mobile) === false) {
        Message({
          message: '请输入正确的手机号码',
          type: 'error'
        })
      } else {
        that.resetloginValidateCodeByManager(() => {
          that.resetnumber = 60
          const min = setInterval(function () {
            if (that.resetnumber > -1) {
              that.resetChangeMine()
            } else {
              clearInterval(min)
            }
          }, 1000)
        })
      }
    },
    sendCodeChange () {
      const that = this
      if (that.number > -1) {
        return
      }
      var reg = /^1(3|4|5|6|7|8|9)\d{9}$/
      if (reg.test(that.login.mobile) === false) {
        Message({
          message: '请输入正确的手机号码',
          type: 'error'
        })
      } else {
        that.loginValidateCodeByManager(() => {
          that.number = 60
          const min = setInterval(function () {
            if (that.number > -1) {
              that.changeMine()
            } else {
              clearInterval(min)
            }
          }, 1000)
        })
      }
    }
  },
  mounted () {
    const that = this
    const min = setInterval(function () {
      if (that.number > -1) {
        that.changeMine()
      } else {
        clearInterval(min)
      }
    }, 1000)
    const restMin = setInterval(function () {
      if (that.resetnumber > -1) {
        that.resetChangeMine()
      } else {
        clearInterval(restMin)
      }
    }, 1000)
    this.validCodeChange()
  }
}
