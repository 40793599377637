export default {
  name: 'select-multiple',
  data () {
    return {
      inputValue: '',
      dataList: [],
      bl: false
    }
  },
  props: [
    'nodekey',
    'defaultProps',
    'data',
    'width',
    'value'
  ],
  computed: {
    selectValue: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('onSetValue', v)
      }
    }
  },
  methods: {
    getList () {
      this.dataList = JSON.parse(JSON.stringify(this.data))
      const arr = []
      this.getAllArr(arr, this.selectValue)
      const ids = arr.map(item => {
        return item.id
      })
      this.$refs.tree.setCheckedKeys(ids)
    },
    /**
     * 获取全选
     * */
    getAllArr (list, data) {
      for (const item in data) {
        if (data[item].children) {
          this.getAllArr(list, data[item].children)
        } else {
          list.push(data[item])
        }
      }
    },
    handleCheckAllChange () {
      const arr = []
      this.getAllArr(arr, this.dataList)
      const ids = arr.map(item => {
        return item.id
      })
      this.$refs.tree.setCheckedKeys(ids)
      this.selectValue = arr
    },
    handleCheckClearChange () {
      this.$refs.tree.setCheckedKeys([])
      this.selectValue = []
    },
    getTreeValue (data, checked, indeterminate) {
      const arr = this.selectValue
      if (!data.children && checked) {
        if (arr.indexOf(data) < 0) {
          arr.push(data)
        }
      } else {
        if (arr.indexOf(data) > -1) {
          arr.splice(arr.indexOf(data), 1)
        }
      }
      this.selectValue = arr
    },
    onDelete (item) {
      const arr = this.selectValue
      arr.splice(arr.indexOf(item), 1)
      this.selectValue = arr
    },
    evil (fn) {
      var Fn = Function
      return new Fn('return ' + fn)()
    },
    handleSearch (tree, value) {
      // 不满足搜索条件的待删除元素索引数组
      const removeArr = []
      // eslint-disable-next-line no-eval
      // replace时正则匹配，全局匹配
      const regExp = this.evil('/' + value + '/g')
      // 满足条件的字符串匹配为以下内容，红色显示，可以根据自己需要调整以下字符串
      const replaceStr = value
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        this.searchTree(node, i, value, removeArr, replaceStr, regExp)
      }
      // 遍历删除不满足条件的节点
      for (let j = removeArr.length - 1; j >= 0; j--) {
        tree.splice(removeArr[j], 1)
      }
      // 方法执行完成后tree只留下符合搜索条件的节点
    },
    /*
    *递归搜索
     * @param {object} node 树的一个节点Node
     * @param {nameumber} index 当前节点的数组索引
     * @param {string} value 搜索内容
     * @param {array} removeArr 待删除的元素的索引数组
     * @param {string} replaceStr 搜索到匹配内容后替换项
     * @param {object} regExp  replace正则匹配表达式，作为参数传入，避免每次执行时重复创建对象
     */
    searchTree (node, index, value, removeArr, replaceStr, regExp) {
      const children = node.children
      // 针对非叶子节点，需要递归其children节点
      if (children && children.length > 0) {
        const innderArr = []
        for (let i = 0; i < children.length; i++) {
          this.searchTree(children[i], i, value, innderArr, replaceStr, regExp)
        }
        // 如果当前节点不满足搜索条件，则对其children不满足条件的节点执行删除操作
        if (node.name.indexOf(value) === -1) {
          for (let j = innderArr.length - 1; j >= 0; j--) {
            children.splice(innderArr[j], 1)
          }
          /*
          *children节点删除结束后，如果children length为0，
          *并且当前节点也不满足搜索条件，则当前节点也加入删除数组
          */
          if (node.children.length === 0) {
            removeArr.push(index)
          }
        } else {
          // 当前节点非叶子节点，将满足条件内容做标记显示
          node.name = node.name.replace(regExp, replaceStr)
        }
      } else {
        // 叶子节点，直接进行匹配
        if (node.name.indexOf(value) === -1) {
          removeArr.push(index)
        } else {
          // 将满足条件内容做标记显示
          node.name = node.name.replace(regExp, replaceStr)
        }
      }
    },
    selectGroup () {
      if (this.inputValue && this.inputValue !== '') {
        this.handleSearch(this.dataList, this.inputValue)
      } else {
        this.dataList = JSON.parse(JSON.stringify(this.data))
      }
      const arr = []
      this.getAllArr(arr, this.selectValue)
      const ids = arr.map(item => {
        return item.id
      })
      this.$refs.tree.setCheckedKeys(ids)
    }
  }
}
