const tagPages = {
  state: {
    cachedViews: [],
    decachedViews: []
  },
  mutations: {
    ADD_CACHED_VIEW: (state, view) => {
      for (const key in state.decachedViews) {
        if (state.decachedViews[key] === view.name) {
          state.decachedViews.splice(key, 1)
          break
        }
      }
      if (state.cachedViews.includes(view.name)) return
      state.cachedViews.push(view.name)
    },
    DELETE_CACHED_VIEW (state, text) {
      for (const key in state.cachedViews) {
        if (state.cachedViews[key] === text) {
          state.cachedViews.splice(key, 1)
          break
        }
      }
      if (state.decachedViews.includes(text)) return
      state.decachedViews.push(text)
    }
  },
  actions: {
    addCachedView (context, text) {
      context.commit('ADD_CACHED_VIEW', text)
    },
    delCachedView (context, text) {
      context.commit('DELETE_CACHED_VIEW', text)
    }
  }
}
export default tagPages
