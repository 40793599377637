const global = {
  state: {
    ajax_loading: false
  },
  mutations: {
    SET_LOADING (state, text) {
      state.ajax_loading = text
    }
  },
  actions: {
    setAjaxLoading (context, text) {
      context.commit('SET_LOADING', text)
    }
  }
}
export default global
