import Vue from 'vue'
import VueRouter from 'vue-router'
import Modules from '../components/modules'
import RouteView from '../components/routeView'

Vue.use(VueRouter)
// name: 'index' 则不显示在tabs标签内
const routes = [
  {
    path: '/',
    redirect: to => {
      return 'login'
    },
    hidden: true
  },
  {
    path: '/login',
    component: Modules.Login,
    meta: {
      name: '登陆'
    }
  }, {
    path: '/home',
    component: RouteView.RouteHome.Index,
    meta: {
      name: '校长首页',
      keepAlive: true
    },
    children: [
      {
        path: '/',
        redirect: to => {
          return 'index'
        },
        hidden: true
      },
      {
        path: 'index',
        name: 'index',
        meta: {
          name: '首页展示'
        },
        component: Modules.Home.index
      }
    ]
  }, {
    path: '*',
    component: Modules.notFindComponent
  }, {
    path: '/evaluation',
    component: RouteView.RouteIndex.Index,
    meta: {
      name: '测评中心',
      keepAlive: true
    },
    children: [
      {
        path: '/',
        redirect: to => {
          return 'create-teacher-papers'
        },
        hidden: true
      },
      {
        path: 'create-teacher-papers',
        name: 'create-teacher-papers',
        meta: {
          name: '创建老师试卷'
        },
        component: Modules.Evaluation.createTeacherPapers
      },
      {
        path: 'analysis',
        name: 'analysis',
        meta: {
          name: '测评数据分析'
        },
        component: Modules.Evaluation.analysis
      },
      {
        path: 'analysis/:paperId',
        name: 'index',
        meta: {
          name: '校区成绩'
        },
        component: Modules.Evaluation.schoolsAnalysis
      },
      {
        path: 'del-analysis',
        name: 'del-analysis',
        meta: {
          name: '资料回收站'
        },
        component: Modules.Evaluation.delAnalysis
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
