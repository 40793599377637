export default {
  name: 'header-tabs',
  data () {
    return {
      editableTabsValue: '2',
      tabIndex: 2,
      currPath: ''
    }
  },
  computed: {
    route () {
      return this.$store.state.tabs.router
    },
    currentPath: {
      get () {
        return this.$store.state.tabs.currentPath
      },
      set (v) {
        this.currPath = this.$store.state.tabs.currentPath
      }
    },
    path () {
      return this.$route.path
    }
  },
  mounted () {

  },
  methods: {
    isActive (route) {
      return route === this.$route.path
    },
    onRemoveTab (targetPath) {
      const rot = this.$store.state.tabs.router
      if (this.isActive(targetPath)) {
        if (this.$store.state.tabs.router.length > 0) {
          for (const item in rot) {
            if (rot[item].path === targetPath) {
              if (item > 0 || item === rot.length - 1) {
                this.$router.push(rot[item - 1].path)
              } else {
                this.$router.push(rot[1].path)
              }
              break
            }
          }
        } else {
          const root = JSON.parse(this.$store.state.login.rootMenu)
          for (const item in root) {
            if (root[item].nodes.length > 0) {
              this.$nextTick(() => {
                const menuPath = root[item].nodes[0].menuPath
                const path = menuPath.split('.html').join('')
                this.$router.push({
                  path: '/' + path
                })
              })
              break
            }
          }
        }
      }
      // 循环匹配当前tabs的值
      for (const item in rot) {
        if (rot[item].path === targetPath) {
          this.$store.dispatch('delCachedView', rot[item].component)
          break
        }
      }
      this.$store.dispatch('deleteRouteList', targetPath)
    },
    onClickTab (targetPath) {
      this.$router.push({
        path: targetPath.name
      })
    }
  }
}
