import settings from '../config/settings.js'
const login = {
  state: {
    token: localStorage.getItem('bell_admin_token'),
    userInfo: localStorage.getItem('bell_admin_user_info'),
    logininfo: {
      loginName: localStorage.getItem('hac_admin_login_name') ? localStorage.getItem('hac_admin_login_name') : '',
      loginPwd: localStorage.getItem('hac_admin_login_pwd') ? localStorage.getItem('hac_admin_login_pwd') : ''
    },
    otoStatus: localStorage.getItem('bell_admin_login_oto') ? localStorage.getItem('bell_admin_login_oto') : '',
    zoneId: localStorage.getItem('bell_admin_login_zoneId') ? localStorage.getItem('bell_admin_login_zoneId') : ''
  },
  mutations: {
    SET_LOGIN (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'token', text)
      state.token = text
    },
    SET_USER (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'user_info', text)
      state.userInfo = text
    },
    SET_USER_LOGIN (state, text) {
      localStorage.setItem(settings.gbs.db_prefix + 'login_name', text.loginName)
      localStorage.setItem(settings.gbs.db_prefix + 'login_pwd', text.loginPwd)
    },
    DEL_USER_LOGIN () {
      localStorage.removeItem('bell_admin_login_name')
      localStorage.removeItem('bell_admin_login_pwd')
      localStorage.removeItem('bell_admin_token')
    },
    SET_OTO_STATUS (state, text) {
      state.otoStatus = text
      localStorage.setItem(settings.gbs.db_prefix + 'login_oto', text)
    },
    SET_ZONE_ID (state, text) {
      state.zoneId = text
      localStorage.setItem(settings.gbs.db_prefix + 'login_zoneId', text)
    }
  },
  actions: {
    loginIn (context, text) {
      context.commit('SET_LOGIN', text)
    },
    setUserInfo (context, text) {
      context.commit('SET_USER', text)
    },
    setUserLogin (context, text) {
      context.commit('SET_USER_LOGIN', text)
    },
    delUserLogin (context) {
      context.commit('DEL_USER_LOGIN')
    },
    setOtoStatus (context, text) {
      context.commit('SET_OTO_STATUS', text)
    },
    setZoneId (context, text) {
      context.commit('SET_ZONE_ID', text)
    }
  }
}
export default login
