import createTeacherPapers from './createTeacherPapers/index.vue'
import analysis from './analysis/index.vue'
import delAnalysis from './delAnalysis/index.vue'
import schoolsAnalysis from './schoolsAnalysis/index.vue'
export default {
  createTeacherPapers,
  analysis,
  delAnalysis,
  schoolsAnalysis
}
