module.exports = {
  message: {
    transcodeState_QUEUED: '正在排队等待转换',
    transcodeState_PROCESSING: '转换中',
    transcodeState_FINISHED: '转换完成',
    Username: '用户名',
    Password: '密码',
    Captcha: '验证码',
    Language: '语言',
    zh: '中文',
    en: '英文'
  }
}
