import ajax from './ajax'
export default {
  // 用户管理-登陆、用户设置
  UserLogin: {
    login (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/auth/login', data, success, false, errFn)
    }
  },
  Home: {
    // 在校员工情况
    getZoneTeacherByPosition (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/teacher/getZoneTeacherByPosition', data, success, false, errFn)
    },
    // 在校员工情况
    getZoneStudentByGrade (data, success, errFn) {
      ajax.call(this, 'get', 'app/rest/school/auth/student/getZoneStudentByGrade', data, success, false, errFn)
    }
  },
  Evaluation: {
    getPaperInfo (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getPaperInfo', data, success, false, errFn)
    },
    getTopCategory (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getTopCategory', data, success, false, errFn)
    },
    getCategoryMap (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getCategoryMap', data, success, false, errFn)
    },
    addExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/addExamPaper', data, success, false, errFn)
    },
    stickPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/stickPaper', data, success, false, errFn)
    },
    updateExamPaperStatus (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaperStatus', data, success, false, errFn)
    },
    getExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getExamStatics', data, success, false, errFn)
    },
    getExamPaperRecycles (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getExamPaperRecycles', data, success, false, errFn)
    },
    recyclePaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/recyclePaper', data, success, false, errFn)
    },
    updateExamPaperLifeByIds (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaperLifeByIds', data, success, false, errFn)
    },
    getExamPaper (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getExamPaper', data, success, false, errFn)
    },
    updateExamPaper (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/updateExamPaper', data, success, false, errFn)
    },
    getSchoolExamStatics (data, success, errFn) {
      ajax.call(this, 'post', 'web/rest/exam/getSchoolExamStatics', data, success, false, errFn)
    },
    getSchoolPerformancies (data, success, errFn) {
      ajax.call(this, 'get', 'web/rest/exam/getSchoolPerformancies', data, success, false, errFn)
    }
  }
}
