const header = {
  state: {
    isCollapse: false
  },
  mutations: {
    CHANGE_IS_COLLAPSE: (state, text) => {
      state.isCollapse = text
    }
  },
  actions: {
    changeIsCollapse (context, text) {
      context.commit('CHANGE_IS_COLLAPSE', text)
    }
  }
}
export default header
