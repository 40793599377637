export default {
  name: 'header-crumb',
  data () {
    return {
    }
  },
  computed: {
    route () {
      return this.$route
    }
  },
  methods: {
    onDropDown (e) {
      if (e) {
        this.$data.dropIcon = 'el-icon-arrow-up'
      } else {
        this.$data.dropIcon = 'el-icon-arrow-down'
      }
    }
  }
}
